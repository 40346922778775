const isClient = typeof window !== 'undefined';

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/
export const noticeError = (err, attrs = {}) => {
  if (isClient && window.newrelic && err) {
    window.newrelic.noticeError(err, attrs);
  }
};

export const noticeApiError = (statusCode, error) => {
  if (![400, 401, 403].includes(Number(statusCode))) {
    noticeError(error);
  }
};

export const wrapConsole = () => {
  if (isClient && window.newrelic) {
    window.newrelic.wrapLogger(console, 'log', { level: 'debug' });
    window.newrelic.wrapLogger(console, 'warn', { level: 'warn' });
    window.newrelic.wrapLogger(console, 'error', { level: 'error' });
  }
};

export const setApplicationVersion = version => {
  if (isClient && window.newrelic) {
    window.newrelic.setApplicationVersion(version);
  }
};

export const setUserId = userId => {
  if (isClient && window.newrelic) {
    window.newrelic.setUserId(userId);
  }
};

const onUnhandledRejection = event => noticeError(event.reason);

export const newrelicAddListeners = () => window.addEventListener('unhandledrejection', onUnhandledRejection);
export const newrelicRemoveListeners = () => window.removeEventListener('unhandledrejection', onUnhandledRejection);
