import { isWebview } from '@/scripts/webview';
import { applicationPrivateTypes as types, clientStoreTypes } from '@/store/types';
import config from '@/config';
import * as units from '@/config/units';
import { UNITS, UNIT_TO_CONFIGURATION } from '@/scripts/constants/units';
import { URLS_BY_UNIT, BASE_URLS, BLOSSOM_URLS } from '@/scripts/constants';
import useDomainChecker from '@/composables/domainChecker';

import { installZendeskForUnit } from '@/plugins/zendesk';
import { CONFIGURATIONS } from '@/scripts/constants/configurations';

const defaultApplicationState = () => ({
  unit: config.unitName,
  deviceSource: '',
  isReferralBannerClosed: false,
  isMultimodeReferralBannerClosed: false,
  interstitialData: null,
  experiments: {},
});

export default {
  namespaced: true,
  state: defaultApplicationState(),
  getters: {
    [types.getters.UNIT]: state => units[state.unit] || {},
    [types.getters.UNIT_NAME]: state => state.unit,
    [types.getters.IS_REFERRAL_BANNER_CLOSED]: state => state.isReferralBannerClosed,
    [types.getters.IS_MULTIMODE_REFERRAL_BANNER_CLOSED]: state => state.isMultimodeReferralBannerClosed,
    [types.getters.IS_SWORD]: state => state.unit === UNITS.SWORD,
    [types.getters.IS_BLOOM]: state => state.unit === UNITS.BLOOM,
    [types.getters.IS_MOVE]: state => state.unit === UNITS.MOVE,
    [types.getters.IS_DPT_GO]: state => state.unit === UNITS.DPT_GO,
    [types.getters.IS_MIND]: state => state.unit === UNITS.MIND,
    [types.getters.SUPPORT_EMAIL_BY_UNIT]: (state,
      getters,
      rootState,
      rootGetters) => {
      const clientConfiguration = rootGetters[clientStoreTypes.getters.GET_CLIENT_CONFIGURATION];
      const defaultEmail = clientConfiguration(CONFIGURATIONS.SUPPORT_EMAIL);

      const configurationName = UNIT_TO_CONFIGURATION[getters[types.getters.UNIT_NAME]];
      const supportEmailByUnit = clientConfiguration(configurationName)?.support_email;

      return supportEmailByUnit || defaultEmail || 'help@swordhealth.com';
    },
    [types.getters.URLS]: (state, getters) => {
      const { isBlossom } = useDomainChecker();

      if (isBlossom.value) {
        return BLOSSOM_URLS;
      }

      return URLS_BY_UNIT[getters[types.getters.UNIT_NAME]] ?? BASE_URLS;
    },
    [types.getters.GET_EXPERIMENTS]: state => state.experiments,
  },
  mutations: {
    [types.mutations.SET_UNIT](state, { unitName }) {
      state.unit = unitName;
      installZendeskForUnit(unitName);
    },
    [types.mutations.SET_DEVICE_SOURCE](state, deviceSource) {
      state.deviceSource = deviceSource;
    },
    [types.mutations.SET_IS_REFERRAL_BANNER_CLOSED](state, isClosed) {
      state.isReferralBannerClosed = isClosed;
    },
    [types.mutations.SET_IS_MULTIMODE_REFERRAL_BANNER_CLOSED](state, isClosed) {
      state.isMultimodeReferralBannerClosed = isClosed;
    },
    [types.mutations.SET_INTERSTITIAL_DATA](state, interstitial) {
      state.interstitialData = interstitial;
    },
    [types.mutations.SET_EXPERIMENTS](state, experiments) {
      state.experiments = { ...state.experiments, ...experiments };
    },
  },
  actions: {
    [types.actions.SET_DEVICE_SOURCE]({ commit }) {
      const deviceSource = isWebview(navigator.userAgent) ? 'webView' : 'web';
      commit(types.mutations.SET_DEVICE_SOURCE, deviceSource);
    },
    [types.actions.SET_UNIT]({ commit, getters }, unitName) {
      // hibloom specs never change
      if (config.unitName === UNITS.BLOOM) unitName = UNITS.BLOOM;

      commit(types.mutations.SET_UNIT, { unitName });
      commit('appointment/setKeepPT', getters.isBloom, { root: true });
    },
  },
};
