/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import { chatPrivateTypes as types } from '@/store/types';

const IDLE_TIMEOUT_IN_MS = 10 * 60 * 1000; // 10 minutes

// STATE
const defaultState = {
  // If the chat widget is open
  isOpen: false,
  // If the chat widget is loading conversations
  isLoadingConversations: false,
  // List of conversations. Each conversation has an id, a list of messages and a status.
  conversations: [],
  // If the chat widget is sending a message
  isSendingMessage: false,
  // If the feedback form should be shown
  showFeedbackForm: false,
  // Timeout to set the conversation as terminated when the user is idle for too long
  idleTimeout: null,
};

// GETTERS
const _getters = {
  [types.getters.IS_OPEN]: state => state.isOpen,
  [types.getters.GET_CONVERSATIONS]: state => state.conversations,
  [types.getters.IS_LOADING_CONVERSATIONS]: state => state.isLoadingConversations,
  [types.getters.IS_SENDING_MESSAGE]: state => state.isSendingMessage,
  [types.getters.GET_UNREAD_MESSAGES_COUNT]: state => {
    const unreadMessages = state.conversations
      .at(-1)
      ?.messages.filter(msg => msg.type === 'bot' && msg.read === false);
    return unreadMessages?.length ?? 0;
  },
  [types.getters.GET_SHOW_FEEDBACK_FORM]: state => state.showFeedbackForm,
  [types.getters.GET_LAST_CONVERSATION]: state => state.conversations.at(-1),
  [types.getters.GET_SHOW_FEEDBACK_BANNER]: state => {
    // We only show the feedback banner if the user has not rated the last conversation, and
    // the feedback form is not shown
    const lastConversation = state.conversations.at(-1);
    return (
      !state.showFeedbackForm
      && lastConversation?.rating === null
      && lastConversation?.comment === null
    );
  },
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_OPEN]: (state, open) => {
    state.isOpen = open;
  },
  [types.mutations.SET_CONVERSATIONS]: (state, conversations) => {
    state.conversations = conversations;
  },
  [types.mutations.SET_LOADING_CONVERSATIONS]: (state, loading) => {
    state.isLoadingConversations = loading;
  },
  [types.mutations.SET_SENDING_MESSAGE]: (state, sending) => {
    state.isSendingMessage = sending;
  },
  [types.mutations.SET_MESSAGES]: (state, { conversationUuid, messages }) => {
    state.conversations = state.conversations.map(conv => {
      if (conv.uuid === conversationUuid) {
        return { ...conv, messages };
      }
      return conv;
    });
  },
  [types.mutations.SET_MESSAGE_READ]: (state, messageId) => {
    state.conversations = state.conversations.map((conv, idx) => {
      if (idx === state.conversations.length - 1) {
        return {
          ...conv,
          messages: conv.messages.map(msg => {
            if (msg.id === messageId) {
              return { ...msg, read: true };
            }
            return msg;
          }),
        };
      }
      return conv;
    });
  },
  [types.mutations.SET_SHOW_FEEDBACK_FORM]: (state, show) => {
    state.showFeedbackForm = show;
  },
  [types.mutations.SET_IDLE_TIMEOUT]: (state, callback) => {
    if (state.idleTimeout) {
      clearTimeout(state.idleTimeout);
    }
    state.idleTimeout = setTimeout(callback, IDLE_TIMEOUT_IN_MS);
  },
  [types.mutations.CLEAR_IDLE_TIMEOUT]: state => {
    clearTimeout(state.idleTimeout);
    state.idleTimeout = null;
  },
};

// ACTIONS
const actions = {
  [types.actions.TOGGLE_OPEN]: ({ commit, state, dispatch }) => {
    commit(types.mutations.SET_OPEN, !state.isOpen);
    if (state.isOpen) {
      // If we are opening the chat, and the user has no conversations, we should start a new one
      if (!state.isLoadingConversations && state.conversations.length === 0) {
        // Start a new conversation
        dispatch(types.actions.START_NEW_CONVERSATION);
      }
    }
  },
  [types.actions.GET_CONVERSATIONS]: async ({ commit, dispatch }) => {
    commit(types.mutations.SET_LOADING_CONVERSATIONS, true);
    const { data } = await axios.get('/member/v1/chat/conversation');
    commit(types.mutations.SET_CONVERSATIONS, data.conversations);
    commit(types.mutations.SET_LOADING_CONVERSATIONS, false);
    dispatch(types.actions.RESET_IDLE_TIMER);
  },
  [types.actions.SEND_MESSAGE]: async ({ commit, state, dispatch }, message) => {
    commit(types.mutations.SET_SENDING_MESSAGE, true);
    // Get last conversation (the one that is currently open)
    const conversation = state.conversations.at(-1);
    if (!conversation) {
      return;
    }
    const originalMessages = [...conversation.messages];

    // Check if the last message is a fake loading message
    const lastMessage = originalMessages.at(-1);
    const isLastMessageFakeLoading = lastMessage && lastMessage.type === 'bot' && lastMessage.content === null;

    // Remove the last message if it's a fake loading message
    const updatedMessages = isLastMessageFakeLoading ? originalMessages.slice(0, -1) : originalMessages;

    // Optimistically add user message
    updatedMessages.push({
      id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      content: message,
      type: 'member',
      created_at: new Date().toISOString(),
    });

    // Create a fake loading message from AI to show the loading dots animation
    const fakeLoadingAiMessage = {
      id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      content: null,
      type: 'bot',
      created_at: new Date().toISOString(),
      read: true,
    };

    // Update the messages
    commit(types.mutations.SET_MESSAGES, {
      conversationUuid: conversation.uuid,
      messages: [
        ...updatedMessages,
        // add a fake loading message from AI to show the loading dots animation
        fakeLoadingAiMessage,
      ],
    });

    const res = await axios.post(`/member/v1/chat/conversation/${conversation.uuid}`, {
      user_message: message,
    }).catch(e => {
      // Ignore 409 errors (conflict) because that just means we are waiting for a newer message
      // from the AI due to consecutive messages
      const errorJson = e.toJSON();
      if (errorJson.status === 409) {
        return;
      }
      throw e;
    });

    if (!res) {
      // Ensuring we exit if the response was undefined due to a 409 error
      // If the response is empty, it means we are waiting for a newer message from the AI
      // We should not update the conversation with the response
      commit(types.mutations.SET_SENDING_MESSAGE, false);
      return;
    }

    // Response type = conversation
    const { data } = res;

    // We can just replace the last message (fake loading ai message) with the last message from the
    // response (actual AI message)
    commit(types.mutations.SET_MESSAGES, {
      conversationUuid: conversation.uuid,
      messages: [
        ...updatedMessages,
        {
          ...data.messages.at(-1),
          read: false,
        },
      ],
    });

    commit(types.mutations.SET_SENDING_MESSAGE, false);

    // Check if we are terminating the conversation (by the AI)
    if (data.closed_at !== null) {
      // Update the conversation
      const lastConversation = state.conversations.at(-1);
      lastConversation.closed_at = data.closed_at;
      lastConversation.closed_reason = data.closed_reason;
      commit(types.mutations.SET_CONVERSATIONS, [
        ...state.conversations.slice(0, -1),
        { ...lastConversation },
      ]);
      // Cancel the timer, because we no longer need it
      commit(types.mutations.CLEAR_IDLE_TIMEOUT);
    } else {
      // Else, we should reset the idle timer
      dispatch(types.actions.RESET_IDLE_TIMER);
    }
  },
  [types.actions.RESET_IDLE_TIMER]: ({ commit, dispatch }) => {
    commit(types.mutations.SET_IDLE_TIMEOUT, () => {
      dispatch(types.actions.HANDLE_IDLE_TIMEOUT);
    });
  },
  [types.actions.CLOSE_CONVERSATION]: async ({ state }, reason) => {
    const conversation = state.conversations.at(-1);
    if (!conversation) {
      return;
    }
    await axios.post(`/member/v1/chat/conversation/${conversation.uuid}/close`, {
      reason, // can be 'member_closed' or 'timed_out'
    });
  },
  [types.actions.HANDLE_IDLE_TIMEOUT]: async ({ commit, dispatch, state }) => {
    const conversation = state.conversations.at(-1);
    if (!conversation) {
      return;
    }
    // Close conversation (by the member, because of inactivity)
    await dispatch(types.actions.CLOSE_CONVERSATION, 'timed_out');
    // Set last conversation as terminated
    const updatedConversations = state.conversations.map(conv => {
      if (conv.uuid === conversation.uuid) {
        return { ...conv, closed_at: new Date().toISOString(), closed_reason: 'timed_out' };
      }
      return conv;
    });
    commit(types.mutations.SET_CONVERSATIONS, updatedConversations);
    // Clear idle timeout because the conversation is already terminated
    commit(types.mutations.CLEAR_IDLE_TIMEOUT);
    // Show show the feedback form
    // Wait a bit so the user can read why the conversation was terminated
    setTimeout(() => {
      commit(types.mutations.SET_SHOW_FEEDBACK_FORM, true);
    }, 2000);
  },
  [types.actions.START_NEW_CONVERSATION]: async ({ commit, state }) => {
    const { data } = await axios.post('member/v1/chat/conversation');
    commit(types.mutations.SET_CONVERSATIONS, [...state.conversations, data]);
  },
  [types.actions.SEND_FEEDBACK]: async ({ state }, data) => {
    const conversation = state.conversations.at(-1);
    if (!conversation) {
      return;
    }
    await axios.post(`/member/v1/chat/conversation/${conversation.uuid}/feedback`, data);
  },
  [types.actions.SEND_TRANSCRIPT]: async ({ commit, state }) => {
    const conversation = state.conversations.at(-1);
    if (!conversation) {
      return;
    }
    await axios.post(`/member/v1/chat/conversation/${conversation.uuid}/transcript`);
    const updatedConversations = state.conversations.map(conv => {
      if (conv.uuid === conversation.uuid) {
        return { ...conv, transcript_sent_at: new Date().toISOString() };
      }
      return conv;
    });
    commit(types.mutations.SET_CONVERSATIONS, updatedConversations);
  },
};

// Conversation and messages types
// {
//   "uuid": "29fdc917-690e-4216-96d5-550d46821aa9",
//   "account_uuid": "d1283967-2987-11ef-91b6-5afbfb46394a",
//   "closed_at": null,
//   "closed_reason": null,
//   "created_at": "2024-06-13T13:32:56Z",
//   "rating": null,
//   "comment": null,
//   "transcript_sent_at": "2024-06-13T13:32:56Z",
//   "messages": [
//       {
//           "id": 1,
//           "content": "Hey 👋 I'm Mary. I'm a Care Coordinator at SWORD. How can I help you?",
//           "type": "bot",
//           "created_at": "2024-06-13T13:32:56Z"
//       }
//   ]
// }

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
