const isEmpty = value => value === null || value === undefined || value.length === 0 || Object.keys(value).length === 0;

const isNotEmpty = value => !isEmpty(value);

const capitalize = value => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
};

function truncateText(text, maxChars) {
  if (typeof text !== 'string' || text === null || text === undefined) {
    return '';
  }

  return text.length > maxChars ? `${text.slice(0, maxChars)}...` : text;
}
// The animation delay used in dynamic forms. Make sure this value matches the CSS transition duration.
export const DYNAMIC_FORMS_ANIMATION_DELAY = 400;

/**
 * Scrolls to the top of the window after a delay.
 * @param {number} ms - The delay in milliseconds before scrolling to the top.
 * @param {Function} callback - The callback function to be executed after the delay.
 */
const delayedScrollToTop = (ms = DYNAMIC_FORMS_ANIMATION_DELAY, callback) => {
  setTimeout(() => {
    if (typeof cb === 'function') {
      callback();
    } else {
      window.scrollTo(0, 0);
    }
  }, ms);
};

/**
 * Formats a number as currency using the specified locale and currency type.
 *
 * @param {number} number - The number to be formatted as currency.
 * @param {string} [locale='en-US'] - The locale to be used for formatting (e.g., 'pt-BR', 'en-US').
 * @param {string} [currency='USD'] - The currency code to be used for formatting (e.g., 'BRL', 'USD').
 * @returns {string} The number formatted as a currency string.
 *
 * @example
 * // Formats 1234.56 as currency in Brazilian Portuguese
 * formatCurrency(1234.56, 'pt-BR', 'BRL'); // "R$ 1.234,56"
 *
 * @example
 * // Formats 1234.56 as currency in US English
 * formatCurrency(1234.56); // "$1,234.56"
 */
function formatCurrency(number, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(number);
}

/**
 * Sanitizes an HTML string by removing all HTML tags.
 *
 *
 * @param {string} str - The HTML string to be sanitized.
 * @returns {string} The sanitized string.
 *
 * @example
 * // Sanitizes an HTML string
 * sanitizeHtml('<p>Hello, <strong>world</strong>!</p>'); // "Hello, world!"
 */
function sanitizeHtml(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Decodes a base64 string into a UTF-8 string.
 *
 * @param {string} str - The base64 string to be decoded.
 * @returns {string} The decoded string.
 */
function decodeBase64(str) {
  // Regular expression to validate Base64 string
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
  const isInvalidBase64Length = str.length % 4 !== 0;

  // Check if the input is a valid Base64 string
  if (!base64Regex.test(str) || isInvalidBase64Length) {
    throw new Error('Invalid Base64 string');
  }

  const buffer = Buffer.from(str, 'base64');
  return buffer.toString('utf-8');
}

export {
  isEmpty,
  isNotEmpty,
  capitalize,
  truncateText,
  delayedScrollToTop,
  formatCurrency,
  sanitizeHtml,
  delay,
  decodeBase64,
};
