<template>
  <div v-if="showModal" class="cookie-modal">
    <div class="title">
      {{ $t('cookies_title') }}
    </div>
    <div class="description">
      {{ $t('cookies_content') }}
      <sword-link
        data-testid="link_privacyStatement"
        :text="$t('onboarding.footer_privacystatement_new')"
        :href="privacyStatementUrl"
        is-quiet
      />
    </div>
    <div class="actions">
      <sword-button
        theme="primary"
        :text="$t('cookies_accept')"
        @click.native="acceptCookie"
      />
      <sword-button
        theme="secondary"
        :text="$t('cookies_reject')"
        @click.native="rejectCookie"
      />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import SwordLink from '@ui-kit/components/links/SwordLink.vue';
import SwordButton from '@ui-kit/components/buttons/SwordButton.vue';
import { mapGetters } from 'vuex';
import { noticeError } from '@/plugins/newrelic-config';

import { isWebview } from '@/scripts/webview';
import { injectTracking, sendTrackingIdentity } from '@/scripts/tracking-util';
import { clientStoreTypes } from '@/store/types';

export default {
  components: {
    SwordLink,
    SwordButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      clientLegalLinks: clientStoreTypes.getters.GET_CLIENT_LEGAL_LINKS,
      clientIdentifiers: clientStoreTypes.getters.GET_CLIENT_IDENTIFIERS,
    }),
    isWebviewUserAgent() {
      return isWebview(navigator.userAgent);
    },
    privacyStatementUrl() {
      const { clientLegalLinks, informedConsentURL } = this;
      return clientLegalLinks?.privacyStatement ?? informedConsentURL;
    },
  },
  watch: {
    '$route.name': {
      handler(name) {
        if (name === 'NPSFeedback') {
          this.showModal = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    try {
      const userSelection = Cookies.get('sword_cookies_acceptance');

      injectTracking();

      // TEMP PINHEIROS
      if (this.$route.name === 'NPSFeedback') return;

      if (this.isWebviewUserAgent && this.clientIdentifiers.reference === 'usswordperk') {
        this.acceptCookie();
        this.showModal = false;
        return;
      }
      // END TEMP PINHEIROS

      this.showModal = !userSelection;
    } catch (error) {
      noticeError(error);
    }
  },
  methods: {
    acceptCookie() {
      sendTrackingIdentity({}, { permission: 'functional' });
      this.saveUserSelection('true');
    },
    rejectCookie() {
      sendTrackingIdentity({}, { permission: 'necessary' });
      this.saveUserSelection('false');
    },
    saveUserSelection(accepted) {
      this.showModal = false;
      try {
        Cookies.set('sword_cookies_acceptance', accepted, { expires: 30 });
      } catch (error) {
        noticeError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 15px;
}

.description {
  font-size: 14px;
  line-height: 26px;
}

.actions {
  display: flex;
  margin-top: 20px;
}

.actions :deep(button) {
  min-width: initial !important;
  margin: 0;
  margin-right: 10px;

  &.--primary {
    background-color: $context-color-1;
    color: white !important;

    &:hover {
      background-color: $context-color-2 !important;
    }
  }
}

.cookie-modal {
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
  position: fixed;
  right: 24px;
  bottom: 80px;
  min-width: 330px;
  max-width: 330px;
  padding: 24px;
  z-index: 500;
  animation: 300ms ease-in 0s 1 normal none running anim-opacity;
}

@keyframes anim-opacity {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
